import React from 'react';
import { CacheProvider } from '@emotion/react';

import { makeMuiCache } from './src/theme/cache';
import './src/styles/app.css';
import { GTM_ID } from './src/utils/constants';

const cache = makeMuiCache();

const initGTMOnEvent = (event) => {
    initGTM();
    event.currentTarget.removeEventListener(event.type, initGTMOnEvent); // remove the event listener that got triggered
};

const initGTM = function () {
    if (window.gtmDidInit) {
        return false;
    }

    window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.

    var script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`;

    script.onload = function () {
        if (window.dataLayer)
            window.dataLayer.push({
                // ensure PageViews are always tracked
                event: 'gtm.js',
                'gtm.start': new Date().getTime(),
                'gtm.uniqueEventId': 0,
            });
    };

    document.head.appendChild(script);
};

export const wrapRootElement = ({ element }) => (
    <CacheProvider value={cache}>{element}</CacheProvider>
);

export const onClientEntry = function () {
    document.onreadystatechange = function () {
        if (document.readyState !== 'loading') setTimeout(initGTM, 3500);
    };

    document.addEventListener('scroll', initGTMOnEvent, { passive: true });
    document.addEventListener('mousemove', initGTMOnEvent, { passive: true });
    document.addEventListener('touchstart', initGTMOnEvent, { passive: true });
};
