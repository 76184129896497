module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/favicon/favicon.svg","icons":[{"src":"/favicon/favicon-16x16.png","sizes":"16x16","type":"png"},{"src":"/favicon/favicon-32x32.png","sizes":"32x32","type":"png"},{"src":"/favicon/mstile-150x150.png","sizes":"150x150","type":"png"},{"src":"/favicon/opencover-android-chrome-192x192.png","sizes":"192x192","type":"png"},{"src":"/favicon/android-chrome-512x512.png","sizes":"512x512","type":"png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7eaac843eb54c6e4bc7ef4f5080abd65"},
    },{
      plugin: require('../node_modules/gatsby-plugin-asset-path/gatsby-browser.js'),
      options: {"plugins":[],"paths":["static","page-data","favicon"],"removeMapFiles":true,"fileTypes":["js","map","css","png","jpg","jpeg","svg","xml"]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://28c448e71ef74ed99c24ff6c22912b1a@o4504235826544640.ingest.sentry.io/4504235827527680","sampleRate":1,"environment":"production","enabled":true,"ignoreErrors":["ChunkLoadError","emitError"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
