export enum ChainId {
    OPTIMISM = 10,
    BASE = 8453,
    BASE_SEPOLIA = 84532,
    ARBITRUM = 42161,
    POLYGON = 137,
    LOCAL_HARDHAT = 31337,
}

export enum AssetId {
    ETH = 0,
    USDC = 2,
    USDT = 3,
    MATIC = 4,
}

export enum AmountInputCurrency {
    ETH = 'ETH',
    USDC = 'USDC',
}

export const POLYGON_IDS = new Set([ChainId.POLYGON]);
export const MAX_CAPACITY_USDC = 2_000_000;
export const MAX_CAPACITY_ETH = 600;

export const NOTIFY_CAPACITY_USDC = 500_000;
export const NOTIFY_CAPACITY_ETH = 150;
export const COVER_30_DAYS_CAPACITY_USDC = 10_000;
export const COVER_30_DAYS_CAPACITY_ETH = 3;

export const COVER_INPUT_AMOUNTS = {
    [AmountInputCurrency.ETH]: {
        min: 0.001,
        max: MAX_CAPACITY_ETH,
        notify: NOTIFY_CAPACITY_ETH,
        forceDaysAmount: COVER_30_DAYS_CAPACITY_ETH,
        decimals: 18,
    },
    [AmountInputCurrency.USDC]: {
        min: 1,
        max: MAX_CAPACITY_USDC,
        notify: NOTIFY_CAPACITY_USDC,
        forceDaysAmount: COVER_30_DAYS_CAPACITY_USDC,
        decimals: 6,
    },
};

export const coverAssets = {
    [AssetId.ETH]: { symbol: 'ETH', decimals: 18 },
    [AssetId.MATIC]: { symbol: 'MATIC', decimals: 18 },
    [AssetId.USDC]: { symbol: 'USDC', decimals: 6 },
    [AssetId.USDT]: { symbol: 'USDT', decimals: 6 },
};
