import createCache, { EmotionCache, Options } from '@emotion/cache';

export const cacheProps: Options = {
    key: 'mui',
    prepend: true,
};

export let muiCache: EmotionCache;

export const makeMuiCache = () => {
    if (!muiCache) {
        muiCache = createCache(cacheProps);
    }

    return muiCache;
};
