exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-the-alpha-memo-tsx": () => import("./../../../src/pages/the-alpha-memo.tsx" /* webpackChunkName: "component---src-pages-the-alpha-memo-tsx" */),
  "component---src-pages-transaction-cover-request-dashboard-tsx": () => import("./../../../src/pages/transaction-cover/request/dashboard.tsx" /* webpackChunkName: "component---src-pages-transaction-cover-request-dashboard-tsx" */),
  "component---src-pages-transaction-cover-request-report-request-id-tsx": () => import("./../../../src/pages/transaction-cover/request/report/[requestId].tsx" /* webpackChunkName: "component---src-pages-transaction-cover-request-report-request-id-tsx" */),
  "component---src-pages-transaction-cover-underwriter-dashboard-request-tsx": () => import("./../../../src/pages/transaction-cover/underwriter/dashboard/request.tsx" /* webpackChunkName: "component---src-pages-transaction-cover-underwriter-dashboard-request-tsx" */),
  "component---src-pages-transaction-cover-underwriter-report-request-request-id-tsx": () => import("./../../../src/pages/transaction-cover/underwriter/report/request/[requestId].tsx" /* webpackChunkName: "component---src-pages-transaction-cover-underwriter-report-request-request-id-tsx" */),
  "component---src-templates-b-2-c-index-tsx": () => import("./../../../src/templates/b2c/index.tsx" /* webpackChunkName: "component---src-templates-b-2-c-index-tsx" */),
  "component---src-templates-cover-index-tsx": () => import("./../../../src/templates/cover/index.tsx" /* webpackChunkName: "component---src-templates-cover-index-tsx" */),
  "component---src-templates-ecosystem-index-tsx": () => import("./../../../src/templates/ecosystem/index.tsx" /* webpackChunkName: "component---src-templates-ecosystem-index-tsx" */),
  "component---src-templates-embed-tsx": () => import("./../../../src/templates/embed.tsx" /* webpackChunkName: "component---src-templates-embed-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-my-covers-index-tsx": () => import("./../../../src/templates/my-covers/index.tsx" /* webpackChunkName: "component---src-templates-my-covers-index-tsx" */),
  "component---src-templates-provider-index-tsx": () => import("./../../../src/templates/provider/index.tsx" /* webpackChunkName: "component---src-templates-provider-index-tsx" */)
}

