import { Chain } from '@rainbow-me/rainbowkit';
import { ChainId } from '../types/cover';
export const GTM_ID = 'GTM-NP8KWV6';
export const SENTRY_DNS =
    'https://28c448e71ef74ed99c24ff6c22912b1a@o4504235826544640.ingest.sentry.io/4504235827527680';
export const EMBEDDED_CHART_URL = 'embed';

export const ALCHEMY_BADGE_ID = 'jY0NDA1NDEwNTMxO';
export const B2C_APP_PATH = '/app/';
export const LOCALHOST_CHAIN: Chain = {
    id: ChainId.LOCAL_HARDHAT,
    name: 'Localhost',
    iconBackground: '#fff',
    nativeCurrency: {
        decimals: 18,
        name: 'Localhost',
        symbol: 'ETH',
    },
    rpcUrls: {
        default: {
            http: ['http://localhost:8545'],
        },
        public: {
            http: ['http://localhost:8545'],
        },
    },
    testnet: true,
};

export const QUOTE_ADDRESS_OPTIMISM_GOERLI = '';
export const QUOTE_ADDRESS_BASE_GOERLI = '';
export const QUOTE_ADDRESS_OPTIMISM = '';
export const QUOTE_ADDRESS_BASE = '';
export const PROVIDER_ID = 255;
